import { createSlice } from "@reduxjs/toolkit";

const storeSlice  = createSlice({
  name: "user",
  initialState: {
    stores: [],
  },
  reducers: {
    setStores: (state, action) => {
      state.stores = action.payload;
    },
  },
});

export const { setStores } = storeSlice .actions;

export default storeSlice .reducer;
