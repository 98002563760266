import React, { useState } from "react";
import { ScreenTopBanner } from "../components";
import { useQuery } from "@tanstack/react-query";
import apis from "../services";
import { useTranslation } from "react-i18next";
import detectLang from "../helper/detectLanguage";

const Coupon = () => {
  const { t } = useTranslation();

  // const [currentDate] = useState(new Date());
  // console.log("currentDate:", currentDate);
  const {
    isLoading,
    error,
    refetch,
    data: { data: getCoupon } = {},
  } = useQuery({
    queryKey: ["getCoupon"],
    queryFn: () => apis.getCoupon(),
    onError: (error) => {
      console.error("Error geting Coupon:", error);
      // toast.error("Failed to fetch products. Please try again later.");
    },
  });
  console.log("getCoupon", getCoupon);
  return (
    <>
      {isLoading ? (
        <>
          <div className="loaderWrapper-cart">
            <div className="loader"></div>
          </div>
        </>
      ) : (
        <section className="container-fluid" id="jtc-token">
          <ScreenTopBanner title={t("titles.myCoupon")} />
          <div id="coupon-parent" className="coupon-page">
            {getCoupon && getCoupon?.data.coupon.length > 0 ? (
              [
                ...getCoupon?.data.coupon.filter((data) => data.is_expired),
                ...getCoupon?.data.coupon.filter((data) => !data.is_expired),
              ].map((data, index) => {
                console.log("DATA:", data);
                return (
                  <div className={`coupon ${data?.is_expired}`} key={index}>
                    <div className="left">
                      {data?.is_expired == false ? (
                        <img
                          src="/assets/images/frame-disable.png"
                          alt="product"
                          style={{
                            width: "91px",
                            height: "124px",
                            objectFit: "contain",
                          }}
                        />
                      ) : (
                        <img src="/assets/images/Frame.png" alt="product" />
                      )}
                    </div>
                    <div className="right">
                      <div className="one">
                        <h2>
                          {data?.Token} {t("titles.token")}
                        </h2>
                        <span>
                          {t("descriptions.couponDesc1")} {data?.code}
                        </span>
                        <p>
                          {data?.isAllProduct !== "1" && (
                            <span>{t("descriptions.couponDesc2")} : </span>
                          )}
                          {data?.products?.length > 0 ? data?.isAllProduct === "1"
                            ? t("descriptions.couponDesc2")
                            : data?.products?.map((products, index) => {
                                return (
                                  <span key={index}>{products?.name} </span>
                                );
                              }) : <>{t("descriptions.couponDesc3")}</>}
                        </p>
                        <p>{data?.endDate}</p>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p className="no-order">{t("messages.noCoupon")}</p>
            )}
          </div>
        </section>
      )}
    </>
  );
};

export default Coupon;
