import { configureStore } from "@reduxjs/toolkit";
import userReducers from "./slice/userSlice";
import productReducer from "./slice/productSlice";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import StoreSlice from "./slice/StoreSlice";

const reducers = combineReducers({
  user: userReducers,
  product: productReducer,
  store: StoreSlice,
});
const persistConfig = {
  key: "user",
  storage,
};
const persistedReducer = persistReducer(persistConfig, reducers);
export default configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
