import { Route, Routes, useLocation } from "react-router-dom";
import {
  Header,
  Footer,
  SignUp,
  Login,
  ProtectedRoute,
  PageLoader,
} from "./components/index";
import {
  Cart,
  Checkout,
  Coupon,
  Favorite,
  Home,
  JtcToken,
  Order,
  ProductDetail,
  Profile,
  Promo,
  Reward,
  ViewAll,
  CartProceedTimer,
  Wallet,
  Referral,
  Notification,
  Logout,
  TermsPolicy,
  Language,
  TermsOfUsePolicy,
} from "./screens/index";
import { ScrollToTop } from "./components";
import axios from "axios";
import { Suspense } from "react";
//styles
import "react-phone-number-input/style.css";
import "./assets/css/style.css";
import "./assets/css/login.css";
import "./assets/css/responsive.css";
import "./App.css";
import ForgotPassword from "./components/Auth/ForgotPassword";
import ConfirmPassword from "./components/Auth/ConfirmPassword";
import { useEffect, useState } from "react";

import ChangePassword from "./components/Auth/ChangePassword";
import { useDispatch, useSelector } from "react-redux";
import LocationModal from "./components/modals/LocationModal";
import { useMutation } from "@tanstack/react-query";
import apis from "./services";
import toast from "react-hot-toast";
import GooglePlacesAutocomplete, {
  getLatLng,
  geocodeByAddress,
} from "react-google-places-autocomplete";
import { setUser } from "./redux/slice/userSlice";

function App() {
  const location = useLocation();
  let { user } = useSelector((store) => store.user);

  const [showLocSuccess, setShowLocSuccess] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState(null);

  const dispatch = useDispatch();

  //OPEN LOCATION MODAL POPUP

  useEffect(() => {
    if (user) {
      if (!user?.lat && !user?.long) {
        setShowLocSuccess(true);
      }
    }
  }, [user]);
  // console.log("ALL CATEGORY PRODUCT:", product);
  const { mutate, isPending } = useMutation({
    mutationFn: apis.updateProfile,
    onError: function ({ message }) {
      console.log("MESSAGE ERROR:", message);
      toast.error(message, { id: 1 });
    },
    onSuccess: ({ data, status }) => {
      setShowLocSuccess(false);
      console.log("data:", data);
      dispatch(setUser(data?.data));
      // toast.success("Profile updated successfully");
    },
  });

  const handleClick = () => {
    console.log("CLICK");

    if (user) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          console.log("latitude", latitude);
          axios
            .get(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyBmaS0B0qwokES4a_CiFNVkVJGkimXkNsk`
            )
            .then((response) => {
              console.log("RESPONSE", response);
              const formattedAddress =
                response.data.results[0].formatted_address;
              // console.log("formattedAddress", formattedAddress);

              mutate({
                lat: latitude,
                long: longitude,
                address: formattedAddress,
              });
            })
            .catch((error) => {
              console.error("Error fetching address:", error);
            });
        },
        (error) => {
          console.error("Error getting user location:", error);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
        }
      );
    }
  };

  useEffect(() => {
    if (selectedPlace) {
      handleAddress(selectedPlace);
    }
  }, [selectedPlace]);
  console.log("selectedPlace", selectedPlace);
  const handlePlaceSelect = async (place) => {
    console.log("FUNCTION RUN,", place);
    setSelectedPlace(place);

    try {
      const results = await getLatLng(place);
      console.log("Latitude:", results.lat);
      console.log("Longitude:", results.lng);
    } catch (error) {
      console.error("Error fetching LatLng:", error);
    }
  };
  const handleAddress = ({ label }) => {
    geocodeByAddress(label)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        mutate({
          lat: lat,
          long: lng,
          address: selectedPlace.label,
        });
        // setDataProceed((prevState) => ({
        //   ...prevState,
        //   latitude: lat.toString(),
        //   longitude: lng.toString(),
        //   address: user.address,
        // }));
      })
      .catch((error) => console.error(error));
  };

  // ------------------------------

  return (
    <Suspense fallback={<PageLoader />}>
      <div className="App">
        <ScrollToTop />

        <Header
          showLocSuccess={showLocSuccess}
          setShowLocSuccess={setShowLocSuccess}
        />

        <Routes>
          <Route path="/" element={<Language />}>
            <Route path=":lang">
              <Route index element={<Home />} />
              <Route path="*" element={<Home />} />

              {user && <Route path="logout" element={<Logout />} />}

              <Route path="viewall/:categoryId" element={<ViewAll />} />
              <Route path="reward" element={<Reward />} />
              <Route path="jtc-token" element={<JtcToken />} />
              <Route
                path="product/:productdetailId"
                element={<ProductDetail />}
              />
              <Route path="privacy-policy" element={<TermsPolicy />} />

              <Route path="term-of-use" element={<TermsOfUsePolicy />} />

              <Route element={<ProtectedRoute />}>
                <Route path="cart" element={<Cart />} />
                <Route path="promo" element={<Promo />} />
                <Route path="coupon" element={<Coupon />} />

                <Route path="favorite" element={<Favorite />} />
                <Route path="profile" element={<Profile />} />
                <Route path="order" element={<Order />} />
                <Route path="wallet" element={<Wallet />} />
                <Route path="referral" element={<Referral />} />
                <Route path="notification" element={<Notification />} />

                <Route path="checkout" element={<Checkout />} />
                <Route
                  path="order-status/:orderId"
                  element={<CartProceedTimer />}
                />
              </Route>

              {/* AUTH ROUTES */}
              <Route path="signup" element={<SignUp />} />
              <Route path="login" element={<Login />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route path="confirm-password" element={<ConfirmPassword />} />
              <Route path="change-password" element={<ChangePassword />} />
            </Route>
          </Route>
        </Routes>

        <Footer />

        <LocationModal
          showLocSuccess={showLocSuccess}
          setShowLocSuccess={setShowLocSuccess}
          handleClick={handleClick}
          isPending={isPending}
          handlePlaceSelect={handlePlaceSelect}
          handleAddress={handleAddress}
          selectedPlace={selectedPlace}
          setSelectedPlace={setSelectedPlace}
        />
      </div>
    </Suspense>
  );
}

export default App;
