// stripe checkout popup

import React, { useState, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import { IoIosWarning } from "react-icons/io";
import { FaCircleCheck, FaCircleXmark } from "react-icons/fa6";
import {
  CardElement,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
} from "@stripe/react-stripe-js";
import apis from "../services";
// import SuccessModal from "./Modal/SuccessModal";
// import { useNavigate } from "react-router-dom";
import useNavigate from "../helper/useNavigate";

import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { clearCart } from "../redux/slice/productSlice";
import { useTranslation } from "react-i18next";
// import { ThreeDots } from "react-loader-spinner";

export default function CheckoutForm({
  handlePaymentModal,
  item,
  dataProceed,
}) {
  // console.log("ITEM DATA:", item?.state?.data);
  // console.log("dataProceed", dataProceed);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(true);
  const stripe = useStripe();
  const elements = useElements();
  const cardStyle = {
    style: {
      base: {
        color: "#000",
        fontFamily: "Poppins, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        border: "1px solid red",
        "::placeholder": {
          color: "#3e3e3e",
        },
      },
      invalid: {
        fontFamily: "Poppins, sans-serif",
        color: "#FF3333        ",
        iconColor: "#FF3333        ",
      },
    },
  };

  const { t } = useTranslation();

  const { user } = useSelector((store) => store.user);

  const [clientSeceretKey, setClientSecretKey] = useState(null);

  const handleChange = async (event) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const { mutate, isPending, data : checkoutDataCard } = useMutation({
    mutationFn: apis.cartCheckout,
    onError: function ({ message }) {
      toast.error(message);
    },
    onSuccess: ({ data: checkout, status }) => {

    },
  });

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);
  
    const cardNumberElement = elements.getElement(CardNumberElement);
    
    if (!cardNumberElement) {
      setError("Card number is not available.");
      setProcessing(false);
      return;
    }
  
    const { error, token } = await stripe.createToken(cardNumberElement);
    
    if (error) {
      setError(error.message);
      setProcessing(false);
      return;
    }
  
    console.log(token, "TOKEN");
  
    if (token) {
      let res;
      try {
        if (dataProceed?.promo_code === "") {
          let { promo_code, ...rest } = dataProceed;
          let charges = dataProceed?.order_type =="delivery" ? (dataProceed?.payment_type== "COINS" ?  item?.data?.tokens_delivery:item?.data?.fiat_delivery) : 0 ;
          res = await apis.cartCheckout({ ...rest, token:token?.id , delivery_charges:charges});
        } else {
          let charges = dataProceed?.order_type =="delivery" ? (dataProceed?.payment_type== "COINS" ?  item?.data?.tokens_delivery:item?.data?.fiat_delivery ): 0 ;
         
          res = await apis.cartCheckout({ ...dataProceed, token:token?.id ,delivery_charges:charges});
        }
        toast.success(t('responses.toast.orderPlace'));
        dispatch(clearCart());
        handlePaymentModal();
        let orderId = res?.data?.data?.order;
        console.log("RESDATA",res.data)
        // console.log("orderIdorderId",orderId)
        navigate(`/order-status/${orderId}`);
      } catch ({ message }) {
        toast.error(message);
        setProcessing(false);
      }
    }
    
    setProcessing(false);
  };
  

  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit} className="pay_form">
        <div className="mb-3">
          <label className="fw-semibold mb-2 d-block position-relative">
            <span class="text-purple position-absolute top-50 end-0 translate-middle-y fs-4 mt-2 pt-1 me-3"></span>
            <span className="card-num-head">{t('titles.cardNo')}</span>
            <CardNumberElement
              options={cardStyle}
              onChange={handleChange}
              className="pay_input "
            />
          </label>
        </div>
        <div className="d-flex mb-3">
          <div className="w-100">
            <label className="fw-semibold mb-2 d-block">
              <span className="card-num-head">{t('titles.expirationDate')}</span>
              <CardExpiryElement
                options={cardStyle}
                onChange={handleChange}
                className="pay_input"
              />
            </label>
          </div>
          <div className="ms-3 w-100">
            <label className="fw-semibold mb-2 d-block">
              <span className="card-num-head">CVC</span>
              <CardCvcElement
                options={cardStyle}
                onChange={handleChange}
                className="pay_input"
              />
            </label>
          </div>
        </div>
        <button
          disabled={processing || disabled || succeeded}
          id="submit"
          className="btn   w-100 btn-pay d-flex justify-content-center align-items-center"
        >
          <span id="button-text">{processing ? t('buttons.loading') : t('titles.payNow')}</span>
        </button>

        {error && (
          <div className="card-error text-danger mt-3" role="alert">
            {error}
          </div>
        )}
      </form>
    </>
  );
}
