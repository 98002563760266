import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import detectLang from "../helper/detectLanguage";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

const JtcTokenCard = ({ data, handleModalOpen, setTokenData }) => {
  console.log("DATATOKEN:", data);
  const { t, i18n } = useTranslation();
  const lang = i18n.language;


  let { user } = useSelector((store) => store.user);

  const [isExpanded, setIsExpanded] = useState(false);
  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };
  const getDescription = () => {
    const description = detectLang(data, "description", lang); 
    const limit = 60; 

    if (isExpanded) {
      return description; 
    } else {
      return (
        description.slice(0, limit) + (description.length > limit ? "..." : "")
      );
    }
  };

  return (
    <>
      <div className={`card ${isExpanded ? "expanded" : ""}`}>
        <div className="top">
          <p>{data?.tokens}</p>
          <img src="/assets/images/token-img.png" alt="card1" width="100%" />
        </div>
        <div className="center">
          <h3>
            {detectLang(data, "name", lang)}
            {/* {data?.name} */}
          </h3>
          <div>
            <p>
              {getDescription()}
              <button onClick={handleToggle}>
                {isExpanded ? t('titles.see_less') : t('titles.see_more')}
              </button>
            </p>
          </div>
          {/* <div className="btn-jtc-token-wrapper">
              <button>View all</button>
            </div> */}
        </div>
        <div className="bottom">
          <button
            onClick={() => {
              if(user){
                handleModalOpen();
                setTokenData(data);
              }else{
                return toast.error(t("responses.toast.errorLogin"), { id: 1 });
              }
           
            }}
          >
            ${data?.amount} MXN
          </button>
        </div>
      </div>
    </>
  );
};

export default JtcTokenCard;
