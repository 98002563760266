import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import apis from "../../services";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { setCart } from "../../redux/slice/productSlice";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const AddToCartModal = ({ show, setShow, data }) => {
  // console.log("DATA ADD TO CART FUNCTION:", data?.quantity_limit);
  const location = useLocation();
  const { t } = useTranslation();

  let addonsDataMap =
    location.pathname == "/favorite" ? data?.product_addons : data?.addons;
    // console.log("addonsDataMapaddonsDataMap",addonsDataMap)

  console.log("LOCATION", location.pathname);

  let { user } = useSelector((store) => store.user);

  const [count, setCount] = useState(1);
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [activeItem, setActiveItem] = useState(null);

  const [activeVariation, setActiveVariation] = useState("small");

  // console.log("activeItem",activeItem)
  const [dataCart, setDataCart] = useState({
    product_id: null,
    addons: [],
    quantity: 1,
    message: "",
  });

  useEffect(() => {
    if (data?.is_variant_check === 1) {
      setDataCart((previousData) => ({
        ...previousData,
        product_variation: activeVariation,
      }));
    }
  }, [data]);

  const getAddVariation = (variation) => {
    setActiveVariation(variation);
    setDataCart((previousData) => ({
      ...previousData,
      product_variation: variation,
    }));
  };

  const getAddOnsID = (addOnID, itemID, variation) => {
    setActiveItem(itemID);
    console.log("addOnID:", addOnID);
    console.log("itemID:", itemID);
    console.log("variation:", variation);

    setDataCart((previousData) => {
      console.log(previousData, "previousData");

      // Check if the addon already exists in the cart
      const existingAddOnIndex = previousData.addons.findIndex(
        (addon) => addon.addon_id === addOnID && addon.addon_items_id === itemID
      );

      if (existingAddOnIndex !== -1) {
        // If the addon exists, remove it
        const updatedAddons = [...previousData.addons];
        updatedAddons.splice(existingAddOnIndex, 1);

        return {
          ...previousData,
          addons: updatedAddons,
        };
      } else {
        // If the addon doesn't exist, add it
        return {
          ...previousData,
          addons: [
            ...previousData.addons,
            { addon_id: addOnID, addon_items_id: itemID },
          ],
        };
      }
    });
  };

  // console.log(dataCart,'dataCartdataCart')
  useEffect(() => {
    setDataCart((previousData) => ({
      ...previousData,
      product_id: Number(data.id),
    }));
  }, [data.id]);
  const decrement = () => {
    if (count > 1) {
      setCount(count - 1);
      setDataCart((previousData) => ({
        ...previousData,

        quantity: count - 1,
      }));
    }
  };

  const increment = () => {
    if(data?.quantity_limit)
   {
    if(dataCart?.quantity >= data?.quantity_limit){
      return toast.error(t("messages.limitOrder"),{id:1})
    }
  }
    setCount(count + 1);
    setDataCart((previousData) => ({
      ...previousData,
      quantity: count + 1,
    }));
  };
  const { mutate, isPending } = useMutation({
    mutationFn: apis.addToCart,
    onError: function ({ message }) {
      toast.error(message);
    },
    onSuccess: ({ data: addToCart, status }) => {
      console.log("DATA-USER-API-addToCart:", addToCart);

      if (status === 200) {
        // Reset count state to 1
        // setCount(1);
        // setMessage("");
        // // Reset dataCart state
        // setDataCart({
        //   product_id: null,
        //   addons: [],
        //   quantity: 1,
        //   message: "",
        // });
        //  MODAL CLOSE
        setShow(false);
        toast.success(t('responses.toast.addedToCart'));
        dispatch(setCart(addToCart?.data));
      }
    },
  });

  // console.log(user,"useruser")
  const handleCartSubmit = () => {
    if (!user) {
      return toast.error(t('responses.toast.errorLogin'), { id: 1 });
    }
    mutate({ ...dataCart, message: message });
  };
  // console.log("dataCart:", dataCart);

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      centered
      size="lg"
      className="add-to-cart-modal"
      // backdrop="static"
      // keyboard={false}
    >
      <div className="modal-content" style={{ borderRadius: "15px" }}>
        <div className="custom-header">
          <button type="button" aria-label="Close">
            <img
              src="/assets/images/back.png"
              alt="back"
              style={{ filter: "brightness(0)" }}
            />
          </button>
          <h3>{t('titles.option')}</h3>
        </div>
        <div className="modal-body">
          {data?.is_variant_check === 1 && (
            <div className="variation-cart">
              <p className="main-head">{t('titles.variation')}</p>
              <div className="variation-item-con">
                <div
                  className={`one ${
                    activeVariation === "small" ? "active" : ""
                  }`}
                  onClick={() => getAddVariation("small")}
                >
                  <p>{t('titles.small')}</p>
                  <p className="text-center">${(+data?.small).toFixed(2)}</p>
                </div>
                <div
                  className={`one ${
                    activeVariation === "medium" ? "active" : ""
                  }`}
                  onClick={() => getAddVariation("medium")}
                >
                  <p>{t('titles.medium')}</p>
                  <p className="text-center">${(+data?.medium).toFixed(2)}</p>
                </div>
                <div
                  className={`one ${
                    activeVariation === "large" ? "active" : ""
                  }`}
                  onClick={() => getAddVariation("large")}
                >
                  <p>{t('titles.large')}</p>
                  <p className="text-center">${(+data?.large).toFixed(2)}</p>
                </div>
              </div>
            </div>
          )}

          <div className="size" bis_skin_checked="1">
            {addonsDataMap?.map((dataItem, index) => {
              return (
                <>
                  <div key={index}>
                    <h3>{dataItem?.addon_name}</h3>
                    <div className="down" bis_skin_checked="1">
                      {dataItem?.addon_items?.map((dataOption, index) => {
                        // console.log("dataOption", dataOption);
                        return (
                          <div
                            key={index}
                            // className="one size"
                            bis_skin_checked="1"
                            className={`one size ${
                              dataCart?.addons.some(
                                (addon) =>
                                  addon.addon_items_id === dataOption?.item_id
                              )
                                ? "active"
                                : ""
                            }`}
                            onClick={() =>
                              getAddOnsID(
                                dataItem?.addon_id,
                                dataOption?.item_id
                              )
                            }
                          >
                            <p>{dataOption?.item_name}</p>
                            <p style={{ textAlign: "center" }}>
                              ${dataOption?.item_price}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              );
            })}
          </div>
          <div className="add-note" bis_skin_checked="1">
            <h3>{t('titles.addNote')}</h3>
            <div className="down" bis_skin_checked="1">
              <textarea
                rows="3"
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>
          </div>

          <div className="counter" bis_skin_checked="1">
            <button className="btn-1" onClick={decrement}>
              <svg
                width="12"
                height="3"
                viewBox="0 0 12 3"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.16 0.875555L0.16 2.14993C0.16 2.39262 0.356737 2.58936 0.599424 2.58936L11.2406 2.58935C11.4833 2.58935 11.68 2.39262 11.68 2.14993L11.68 0.875555C11.68 0.632867 11.4833 0.43613 11.2406 0.43613L0.599424 0.436131C0.356737 0.436131 0.16 0.632868 0.16 0.875555Z"
                  fill="#3E3E3E"
                ></path>
              </svg>
            </button>
            <p id="counterValue">{count}</p>
            <button className="btn-2" onClick={increment}>
              <svg
                width="12"
                height="13"
                viewBox="0 0 12 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.401 5.48358H7.15693V1.23951C7.15693 0.996708 6.96027 0.800049 6.71747 0.800049H5.44344C5.20064 0.800049 5.00398 0.996708 5.00398 1.23951V5.48358H0.759466C0.516666 5.48358 0.320007 5.68024 0.320007 5.92304V7.19706C0.320007 7.43986 0.516666 7.63652 0.759466 7.63652H5.00354V11.8806C5.00354 12.1234 5.20019 12.32 5.44299 12.32H6.71702C6.95982 12.32 7.15648 12.1234 7.15648 11.8806V7.63652H11.4005C11.6433 7.63652 11.84 7.43986 11.84 7.19706V5.92304C11.84 5.68024 11.6433 5.48358 11.4005 5.48358H11.401Z"
                  fill="#3E3E3E"
                ></path>
              </svg>
            </button>
          </div>
        </div>
        <button
          type="button"
          className="cart-btn"
          id="addToCartBtn"
          onClick={handleCartSubmit}
          disabled={isPending}
        >
          {isPending ?  t('buttons.adding') : t('buttons.addToCart')}
        </button>
      </div>
    </Modal>
  );
};
