import React, { useState } from "react";
import apis from "../services";
import toast from "react-hot-toast";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import useNavigate from "../helper/useNavigate";

import { clearCart } from "../redux/slice/productSlice";
import { useDispatch } from "react-redux";
import { MdDelete } from "react-icons/md";
import { MdOutlineRemoveShoppingCart } from "react-icons/md";
import { useTranslation } from "react-i18next";
import detectLang from "../helper/detectLanguage";

const Cart = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    isLoading,
    isPending: isPendingCart,
    error,
    refetch,
    data: { data: productCart } = {},
  } = useQuery({
    queryKey: ["getProductCart"],
    queryFn: () => apis.getViewCart(),
    onError: (error) => {
      console.error("Error fetching products:", error);
      toast.error("Failed to fetch products. Please try again later.");
    },
  });

  const { mutate, isPending } = useMutation({
    mutationFn: (data) => apis.addToCart(data),
    onError: function ({ message }) {
      toast.error(message, { id: 1 });
    },
    onSuccess: ({ data: addToCart, status, message }) => {
      if (status === 200) {
        refetch();
        toast.success(addToCart?.message);
      }
    },
  });

  const { mutate: mutateClearCart } = useMutation({
    mutationFn: () => apis.clearCart(),
    onError: function ({ message }) {
      toast.error(message);
    },
    onSuccess: ({ data: addToCart, status }) => {
      if (status === 200) {
        refetch();
        dispatch(clearCart());
        toast.success(t("responses.toast.clear_cart"));
      }
    },
  });

  const transformAddonData = (addons) => {
    let transformedData = [];

    addons.forEach((addon) => {
      addon.addon_items.forEach((item) => {
        transformedData.push({
          addon_id: addon.addon_id,
          addon_items_id: item.item_id,
        });
      });
    });

    return transformedData;
  };

  const decrement = (
    cart_id,
    product_id,
    addons,
    quantity,
    product_variation
  ) => {
    const newDataCart = {
      cart_id: cart_id,
      product_id: product_id,
      addons: [],
      quantity: quantity,
    };
    if (quantity >= 1) {
      mutate(newDataCart);
    }
  };

  const increment = (
    cart_id,
    product_id,
    addons,
    quantity,
    product_variation,
    quantity_limit
  ) => {
    const newDataCart = {
      product_id: product_id,
      addons: [],
      quantity: quantity,
      cart_id: cart_id,
    };
    console.log(quantity,'quantity_limit')
    if(quantity_limit)
    {
      if(quantity > quantity_limit){
        return toast.error(t("messages.limitOrder"),{id:1})
      }
    }
    
    mutate(newDataCart);
  };

  const proceedCheckout = () => {
    Navigate("/checkout", { state: productCart });
  };
  console.log("productCart", productCart);

  const deleteCart = async (cart_id) => {
    console.log("CLICK", cart_id);
    try {
      console.log("CLICK");
      const response = await apis.removeProductFromCart({
        id: Number(cart_id),
      });
      console.log("RES", response);
      if (response.status === 200) {
        console.log("RESPONSE DLETE", response);
        refetch(); // Refetch the cart data to update UI
        toast.success(t("responses.toast.single_cart"));

        if (response?.data?.data?.cart?.length === 0) {
          console.log("calll dispatch");
          dispatch(clearCart());
        }
      } else {
        toast.error("Failed to remove item from cart. Please try again later.");
      }
    } catch (error) {
      console.error("Error removing item from cart:", error);
      toast.error("Failed to remove item from cart. Please try again later.");
    }
  };

  console.log(productCart?.data?.cart, "productCart?.data?.cart");

  const getPriceBySize = (productData, size) => {
    console.log(size, "sizesizesize");
    // Check if productData exists and is_variant_check is enabled
    if (productData && productData.is_variant_check === 1) {
      // Determine the property name based on the size argument
      const propertyName = `${size}`;
      // Check if the property exists in the productData
      if (propertyName in productData) {
        // Return the price corresponding to the size
        return productData[propertyName];
      } else {
        // Return null if the size property is not found
        return productData["small"];
      }
    } else {
      // Return null if variant checking is disabled
      return productData["small"];
    }
  };

  return (
    <>
      {isLoading || isPendingCart ? (
        <>
          <div className="loaderWrapper-cart">
            <div className="loader"></div>
          </div>
        </>
      ) : productCart?.data?.cart.length > 0 ? (
        <div className="" id="cart">
          <div className="d-flex justify-content-between">
            <h1>{t("titles.cart")}</h1>
            <button onClick={mutateClearCart} className="clear-cart">
              {t("buttons.clearCart")}
            </button>
          </div>
          <div className="main-wrapper">
            <div className="main-parent ">
              <div className="left">
                {productCart?.data?.cart.map((data, index) => {
                  const { product_detail } = data;
                  return (
                    <div className="cart-wrapper-wrapper" key={index}>
                      {console.log("product_detail", data)}
                      <div className="cart-wrapper">
                        <img
                          src={
                            product_detail?.images?.[0] ||
                            "/assets/images/cart/img-1.png"
                          }
                          alt="img-1"
                          className="cart-img"
                        />
                        <div className="con">
                          <div className="up">
                            <h3>
                              {detectLang(product_detail, "name", lang)}
                              {/* {product_detail?.name} */}
                            </h3>
                            {/* <p>
                              {detectLang(product_detail, "description", lang)}

                              {product_detail?.description}
                            </p> */}

                            {data?.addon_details &&
                              data?.addon_details.length > 0 && (
                                <div className="addons-con">
                                  <h5>{t("titles.addOns")}:</h5>
                                  <div className="addons-name">
                                    {data?.addon_details.map(
                                      (addons, index) => (
                                        <>
                                          {/* {console.log("addonsaddonsmm",addons)} */}
                                          <p key={index}>{addons.name}</p>
                                        </>
                                      )
                                    )}
                                  </div>
                                </div>
                              )}

                            {data?.product_variation &&
                              data?.product_variation.length > 0 && (
                                <div className="product-variation-con">
                                  <h4>{t("titles.product_variation")}</h4>
                                  <p>{data?.product_variation}</p>
                                </div>
                              )}
                          </div>
                          {/* <div className="down">
                            <p>
                              {product_detail?.product_addons.length > 0 && (
                                <span>Addons: </span>
                              )}
                              <span>
                                {product_detail?.product_addons.map(
                                  (data, index) => {
                                    return data?.addon_items.map(
                                      (data, index) => {
                                        console.log("ADDONS ITEM NAME:", data);
                                        return (
                                          <span key={index}>
                                            {data?.item_name}
                                          </span>
                                        );
                                      }
                                    );
                                  }
                                )}
                              </span>
                            </p>
                          </div> */}
                        </div>
                      </div>

                      <div className="quantity-wrapper">
                        <div className="delete-cart-price-wrapper">
                          <h2>
                            $
                            {/* {product_detail?.is_variant_check == 1
                              ? getPriceBySize(
                                  product_detail,
                                  data?.product_variation
                                ) * Number(data?.quantity)
                              : Number(product_detail?.product_price) *
                                Number(data?.quantity)} */}
                            {Number(data?.grand_total) * Number(data?.quantity)}
                          </h2>
                          <MdDelete
                            className="cart-delete"
                            onClick={() => deleteCart(data?.id)}
                          />
                        </div>
                        <div className="quantity-con">
                          <p className="qty">QTY</p>
                          <button
                            className="minus"
                            onClick={() =>
                              decrement(
                                data?.id,
                                product_detail?.id,
                                product_detail?.product_addons,
                                data?.quantity - 1,
                                data?.product_variation
                              )
                            }
                          >
                            -
                          </button>
                          <p className="counter" id="counterValue1">
                            {data?.quantity}
                          </p>
                          <button
                            className="plus"
                            onClick={() =>
                              increment(
                                data?.id,
                                product_detail?.id,
                                product_detail?.product_addons,
                                data?.quantity + 1,
                                data?.product_variation,
                                product_detail?.quantity_limit
                              )
                            }
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}

                {/* <button className="add-more">ADD MORE</button> */}
              </div>
            </div>

            <div className="right">
              <div className="two">
                <h2>{t("titles.orderSummary")}</h2>
                <div className="summary-wrapper">
                  <h2>{t("titles.fiat")}</h2>
                  <div className="up">
                    <div className="one">
                      <p className="p1">{t("titles.orderTotal")}</p>
                      <p className="p2">
                        ${Number(productCart?.data?.fiat_subtotal).toFixed(2)}
                      </p>
                    </div>
                    {/* <div className="one">
                      <p className="p1">Delivery Charges</p>
                      <p className="free">{Number(productCart?.data?.delivery).toFixed(2)}</p>
                    </div> */}
                    <div className="one">
                      <p className="p1">{t("titles.tax")}</p>
                      <p className="free">
                        {Number(productCart?.data?.fiat_tax).toFixed(2)}
                      </p>
                    </div>
                    <div className="one">
                      <p className="p1">{t("titles.grandTotal")}</p>
                      <p className="p2">
                        {/* $
                        {(
                          Number(productCart?.data?.tax) +
                          Number(productCart?.data?.delivery)
                        ).toFixed(2)} */}
                        ${Number(productCart?.data?.fiat_total).toFixed(2)}
                      </p>
                    </div>
                  </div>
                  <h2 className="mt-3">JTC {t("titles.token")}</h2>
                  <div className="up">
                    <div className="one">
                      <p className="p1">{t("titles.orderTotal")}</p>
                      <p className="p2">
                        ${Number(productCart?.data?.tokens_subtotal).toFixed(2)}
                      </p>
                    </div>
                    {/* <div className="one">
                      <p className="p1">Delivery Charges</p>
                      <p className="free">{Number(productCart?.data?.tokens_delivery).toFixed(2)}</p>
                    </div> */}
                    <div className="one">
                      <p className="p1">{t("titles.tax")}</p>
                      <p className="free">
                        {Number(productCart?.data?.tokens_tax).toFixed(2)}
                      </p>
                    </div>
                    <div className="one">
                      <p className="p1">{t("titles.grandTotal")}</p>
                      <p className="p2">
                       {Number(productCart?.data?.tokens_total).toFixed(2)}
                      </p>
                    </div>
                  </div>
                  <div className="down">
                    <button onClick={proceedCheckout}>
                      {t("buttons.proceedToCheckout")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="main-wrapper " id="cart">
          <div className="main-parent d-flex justify-content-center align-items-center flex-column">
            <div className="d-flex justify-content-center align-items-center flex-column mt-5 p-4">
              <MdOutlineRemoveShoppingCart size={70} />
              <h1>{t("titles.cartEmpty")}</h1>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Cart;
