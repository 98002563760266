import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Phone = ({ field, form, ...props }) => {
  return (
    <PhoneInput
      {...field}
      {...props}
      country={"mx"}
      onChange={(value) => {
        form.setFieldValue(field.name, value); // Set the field value in Formik
      }}
      onBlur={() => form.setFieldTouched(field.name, true)} // Mark field as touched on blur
    />
  );
};

export default Phone;
