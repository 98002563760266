import React from "react";

const ScreenTopBanner = ({title}) => {
  return (
 
      <div className="heading-bg">
        <h2>{title}</h2>
      </div>
   
  );
};

export default ScreenTopBanner;
