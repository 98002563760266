import React from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

function GoogleSearchInput() {
  <div>
    <GooglePlacesAutocomplete apiKey="AIzaSyBmaS0B0qwokES4a_CiFNVkVJGkimXkNsk" />
  </div>;
}

export default GoogleSearchInput;
