import React, { useEffect, useState } from "react";
import { AddToCartModal } from "../components/modals/AddToCartModal";
import { Link, useLocation } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import apis from "../services";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import reduxApis from "../redux/api";
import { useTranslation } from "react-i18next";
import detectLang from "../helper/detectLanguage";

import CustomLink from "../helper/Link";

const ProductCart = ({ className, data, refetch }) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const dispatch = useDispatch();
  const location = useLocation();

  // console.log("location", location.pathname);
  // let { product } = useSelector((product) => product.product);
  // console.log("datadatadatadatadata", data);
  // console.log("INSIDE PRODUCT CARD", data);
  const [isFavorite, setIsFavorite] = useState(false);
  const [show, setShow] = useState(false);

  let { user } = useSelector((store) => store.user);
  const { stores } = useSelector((store) => store.store);
  console.log("stores?.[0]?.id", stores?.[0]?.id);
  console.log("GET USER IDDD..", user);
  const handleAddToCart = () => {
    setShow(true);
  };


  const fetchData = () => {
    dispatch(
      reduxApis.getAllProductWithCategory({
        userId: user?.id,
        store_id: stores?.[0]?.id,
      })
    );
  };

  //FAVORITE

  const {
    mutate,
    isPending,
    data: { data: favouriteProduct } = {},
  } = useMutation({
    mutationFn: apis.favouriteProduct,
    onError: function ({ message }) {
      toast.error(message);
    },
    onSuccess: ({ status, data }) => {
      if (location.pathname.includes("favorite")) {
        refetch();
      }
      fetchData();

      toast.success(data?.message);
      // setIsFavorite(favourite?.data?.product?.is_favourite);
    },
  });

  const handleFavourite = (event, id) => {
    event.preventDefault();
    event.stopPropagation();
    console.log("IDIDIDIDIDIDIDIDIDIDIDIDIDIDID", id);
    console.log("CLICK");
    // console.log("DATA ID", data.id);

    if (user) {
      mutate({ product_id: Number(id) });
    } else {
      return toast.error(t("responses.toast.errorLogin"), { id: 1 });
    }
  };
  // console.log("favourite Products:", favouriteProduct);

  return (
    <>
      <div className={className}>
        <div className="coffee-card">
          <div className="one">
            <CustomLink
              to={`/product/${
                data?.product?.id ? data?.product?.id : data?.id
              }`}
            >
              <div className="top">
                <div className="top-inner">
                  <img
                    src={
                      data?.images?.[0] ||
                      data?.product?.images?.[0] ||
                      "/assets/images/card-images/coffee-card1.png"
                    }
                    alt="card1"
                  />
                  {/* <div className="discount-bg">
                    Get <span>10%</span> Cash back
                  </div> */}
                </div>
                <div className="left">
                  <img
                    src="/assets/images/card-images/card-logo.png"
                    alt="card"
                  />
                  <p>
                    {data?.small_token_price ||
                      data?.product?.small_token_price}
                  </p>
                </div>
                {/* ------------------ FAVOURITE -------------------------------------- */}
                <div
                  className="right"
                  onClick={(event) => {
                    handleFavourite(
                      event,
                      data?.product?.id ? data?.product?.id : data?.id
                    );
                  }}
                >
                  <svg
                    width="19"
                    height="18"
                    viewBox="0 0 19 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.5442 5.56959V6.36113C18.5323 6.4063 18.5156 6.45146 18.5133 6.499C18.4419 7.33094 18.1995 8.11773 17.8548 8.87361C17.2083 10.2927 16.2575 11.5002 15.1855 12.615C13.4764 14.393 11.5154 15.8548 9.45455 17.1931C9.36185 17.2525 9.30005 17.2406 9.21685 17.1859C7.4555 16.0402 5.76308 14.8066 4.23943 13.3542C3.19831 12.363 2.23801 11.3053 1.47737 10.0787C0.776157 8.95918 0.267481 7.77069 0.158139 6.43482C0.0369127 4.96822 0.429116 3.65374 1.36327 2.52229C3.24823 0.240382 6.54987 -0.087643 8.86743 1.76166C9.02907 1.89001 9.1812 2.03263 9.34521 2.17525C9.42127 2.10632 9.48545 2.04452 9.55201 1.98509C9.93232 1.63092 10.3531 1.33617 10.8237 1.11511C12.4614 0.344969 14.1016 0.366362 15.6965 1.20544C17.0419 1.91378 17.9285 3.02859 18.3397 4.49995C18.4372 4.84937 18.4776 5.21305 18.5465 5.57197L18.5442 5.56959Z"
                      fill={
                        data?.is_favourite || data?.product?.is_favourite
                          ? "#E0201C"
                          : "#3E3E3E"
                      }
                    />
                  </svg>
                </div>
              </div>
              <div className="bottom">
                <h2>
                  {
                    detectLang(
                      data?.product ? data?.product : data,
                      "name",
                      lang
                    )
                    // detectLang(data, "name", lang)
                  }
                  {/* {data?.name || data?.product?.name} */}
                </h2>
                <p>
                  {/* {data?.description || data?.product?.description} */}
                  <p>
                    {
                      detectLang(
                        data?.product ? data?.product : data,
                        "description",
                        lang
                      )
                      // detectLang(data, "description", lang)
                    }
                  </p>
                </p>
                <p className="price">
                  MX
                  <span>
                    {" "}
                    ${data?.small || data?.product?.small}
                    {/* $
                    {Math.floor(
                      Number(data?.product_price) ||
                        Number(data?.product?.product_price)
                    ).toFixed(2)} */}
                  </span>
                </p>
              </div>
            </CustomLink>
            <div className="btn-wrapper">
              <button className="btn-coffee" onClick={handleAddToCart}>
                {t("buttons.addToCart")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <AddToCartModal
        show={show}
        setShow={setShow}
        data={data?.product ? data?.product : data}
      />
    </>
  );
};

export default ProductCart;
