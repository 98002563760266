import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

import { useTranslation } from "react-i18next";
import apis from "../../services";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { setStores } from "../../redux/slice/StoreSlice";
import reduxApis from "../../redux/api";
import toast from "react-hot-toast";

const StoreLocatorModal = ({ storeLocator, setStoreLocator }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let { user } = useSelector((store) => store.user);

  const [showStoreList, setShowStoreList] = useState(false);
  const [visibleStores, setVisibleStores] = useState(1);

  const handleSelectAutomatically = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          //   console.log("Latitude:", latitude, "Longitude:", longitude);
          IsNearestMutate({
            lat: latitude,
            long: longitude,
          });
        },
        (error) => {
          toast.error("Error getting location", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const {
    isLoading,
    error,
    data: { data: getStoreList } = {},
  } = useQuery({
    queryKey: ["getStoreList"],
    queryFn: () => apis.getStoresList(),
    onError: (error) => {
      console.error("Error geting Store list:", error);
    },
  });
  //   console.log("getStoreList", getStoreList?.data);

  // GET NEAREST STORE API
  const { mutate: IsNearestMutate, isPending } = useMutation({
    mutationFn: apis.getNearestStore,
    onError: function ({ message }) {
      console.log("MESSAGE ERROR:", message);
      //   toast.error(message, { id: 1 });
    },
    onSuccess: ({ data, status }) => {
      console.log("Is Nearest Store data", data);
      const nearestStore = data?.data?.[0]?.attributes;

      if (data?.data?.length > 0 && nearestStore) {

        dispatch(setStores(nearestStore));

        dispatch(
          reduxApis.getAllProductWithCategory({
            userId: user?.id,
            store_id: nearestStore?.id,
          })
        );

        setStoreLocator(false);
      } else {
        toast.error("No nearest stores found at your current location.");
      }
    },
  });

  const handleSelectStore = (storeObj) => {
    // console.log("storeObj", storeObj);

    dispatch(setStores(storeObj?.attributes));

    dispatch(
      reduxApis.getAllProductWithCategory({
        userId: user?.id,
        store_id: storeObj?.attributes?.id,
      })
    );

    setStoreLocator(false);
  };

  return (
    <Modal
      show={storeLocator}
      onHide={() => setStoreLocator(false)}
      centered
      size="lg"
      className="storeLocatorModal"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>Select a Store</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="img-con" style={{ display: "none" }}>
          <img src="/assets/images/infyom.png" alt="logo" />
        </div>
        {!showStoreList ? (
          <div className="btn-wrapper">
            <button onClick={handleSelectAutomatically} disabled={isPending}>
              {isPending ? "Searching..." : "Select Automatically"}
            </button>
            <button onClick={() => setShowStoreList(true)}>
              Select Manually
            </button>
          </div>
        ) : (
          <div className="store-list">
            <ul>
              {getStoreList?.data?.slice(0, visibleStores).map((store) => (
                <li key={store.id}>
                  <div className="store-item">
                    <div className="left">
                      <img src="/assets/images/store1.jpg" alt="store1" />
                    </div>
                    <div className="right">
                      <h3 className="store-name">{store?.attributes?.name}</h3>
                      <p className="store-location">
                        {store?.attributes?.address}
                      </p>
                      <button
                        className="select"
                        onClick={() => handleSelectStore(store)}
                      >
                        Select
                      </button>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            <div className="btn-bottom">
              {visibleStores < getStoreList?.data?.length && (
                <button
                  onClick={() => setVisibleStores((prev) => prev + 3)}
                  className="show-more"
                >
                  Show More
                </button>
              )}
              <button onClick={() => setShowStoreList(false)} className="back">
                Back
              </button>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default StoreLocatorModal;
