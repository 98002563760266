import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import apis from "../services";
import { logout } from "../redux/slice/userSlice";

const Logout = () => {
    const dispatch = useDispatch();
   
    useEffect(() => {
        const logoutAndRedirect = async () => {
            try {
                dispatch(logout());
                await apis.authLogout();
                setTimeout(()=>{
                    window.location.href = "/login"
                },2000)
            } catch (error) {
                console.error("Logout failed:", error);
               setTimeout(()=>{
                    window.location.href = "/login"
                },2000)
            }
        };

        logoutAndRedirect();
    }, []);

    return (
        <>
            <div className="w-100 min-vh-100 d-flex justify-content-center align-items-center">
            <div className="loaderWrapper-cart">
            <div className="loader"></div>
          </div>
            </div>
        </>
    );
};

export default Logout;
