import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import moment from "moment";
import apis from "../services";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const CartProceedTimer = () => {
  let { orderId } = useParams();
  const { t, i18n } = useTranslation();

  const { isLoading, error, refetch, data } = useQuery({
    queryKey: ["getOrderDetails"],
    queryFn: () => apis.getOrderDetails(orderId),
    onError: (error) => {
      console.error("Error geting order details:", error);
      // toast.error("Failed to fetch products. Please try again later.");
    },
  });
  console.log(data, "datadatadata");

  const location = useLocation();
  console.log("OrederStatusTimerData:", location);

  // const [showButton, setShowButton] = useState(false);
  // const [formattedTime, setFormattedTime] = useState("");

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     const timeRemaining = calculateTimeRemaining();

  //     if (timeRemaining !== null) {
  //       const duration = moment.duration(timeRemaining);
  //       const days = Math.floor(duration.asDays());
  //       const hours = duration.hours();
  //       const minutes = duration.minutes();
  //       const seconds = duration.seconds();

  //       const formattedTime = `${String(hours).padStart(2, "0")} : ${String(
  //         minutes
  //       ).padStart(2, "0")} : ${String(seconds).padStart(2, "0")}`;

  //       setFormattedTime(formattedTime);
  //     } else {
  //       clearInterval(timer);
  //       setShowButton(false);
  //     }
  //   }, 1000);

  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, [data?.data]);

  // const calculateTimeRemaining = () => {
  //   const now = moment();

  //   const startTime = moment(data?.data?.start_time, "h:mm A");
  //   const endTime = moment(data?.data?.end_time, "h:mm A");

  //   if (now.isBefore(startTime)) {
  //     // Contest hasn't started yet
  //     return startTime.diff(now, "milliseconds");
  //   } else if (now.isBefore(endTime)) {
  //     // Contest is ongoing
  //     return endTime.diff(now, "milliseconds");
  //   }
  // };

  // useEffect(() => {
  //   setShowButton(checkTime());
  //   const timer = setInterval(() => {
  //     setShowButton(checkTime());
  //   }, 1000);

  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, [data?.data]);

  // const checkTime = () => {
  //   const currentTime = moment();
  //   return currentTime.isBetween(
  //     moment(data?.data?.start_time, "h:mm A"),
  //     moment(data?.data?.end_time, "h:mm A")
  //   );
  // };

  // console.log("checkTime", checkTime());
  // console.log("showButton", showButton);
  // console.log("formattedTime", formattedTime);
  // console.log("calculateTimeRemaining", calculateTimeRemaining());
  return (
    <section id="proceed-timer">
     {isLoading ? <>    <div className="loaderWrapper-cart">
            <div className="loader"></div>
          </div></>:<>
      <div className="up">
        <div className="con">
          {/* {data?.data?.order_type !== "delivery" ? (
            checkTime() ? (
              <h1>{formattedTime}</h1>
            ) : (
              <h1>{t("descriptions.orderReadyPickup")}</h1>
            )
          ) : ( */}
            <h1>
              {/* {t("titles.order")}{" "} */}
              {data?.data?.order_status == 0
                ? "Pending"
                : data?.data?.order_status == 1
                ? "Accepted By JTC"
                : data?.data?.order_status == 2
                ? "Preparing"
                : data?.data?.order_status == 3
                ? "Delivered"
                : data?.data?.order_status == 4
                ? "Completed"
                : data?.data?.order_status == 5
                ? "Returned"
                : data?.data?.order_status == 6
                ? "Refunded"
                : data?.data?.order_status == 7
                ? "Cancelled"
                : data?.data?.order_status == 8
                ? "Hold"
                : data?.data?.order_status == 9
                ? "Start Delivery"
                : data?.data?.order_status == 10
                ? "Rider Arrived"
                : data?.data?.order_status == 11
                ? "Rider Pick-Up Order"
                : "Processing"}
            </h1>
          {/* )} */}
          <p>
            {t("messages.thankforOrder")}
            {/* <button className={`preparing ${data?.data?.order_status == 4 ? "dispatched" : "pending"}`}>
                          Order {data?.data?.order_status == 0 ? "Pending" : data?.data?.order_status == 1 ? "Accepted By JTC" : data?.data?.order_status == 2 ? "Preparing": data?.data?.order_status == 3 ? "Delivered" : data?.data?.order_status == 4 ? "Completed" : data?.data?.order_status == 5 ? "Returned":data?.data?.order_status == 6 ? "Refunded": data?.data?.order_status == 7 ? "Cancelled" :  data?.data?.order_status == 8 ? "Hold":data?.data?.order_status == 9 ? "Start Delivery" : data?.data?.order_status == 10 ?"Rider Arrived": data?.data?.order_status == 11 ? "Rider Pick-Up Order":"Processing"}
                        </button> */}
          </p>
        </div>
      </div>
      <div className="down">
        <div className="con">
          {/* <img
            src={
              checkTime()
                ? "/assets/images/timer.png"
                : "/assets/images/verified.png"
            }
            alt="timer"
          />
          {checkTime() && <p className="patience">{t("titles.bePatient")}</p>} */}


           <p className="patience">{t("titles.bePatient")}</p>

          <Link to="/">
            <button type="submit">{t("buttons.goHome")}</button>
          </Link>
        </div>
      </div>
      </>}
    </section>
  );
};

export default CartProceedTimer;
