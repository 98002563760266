import React, { useEffect, useState } from "react";
import {
  BannerSlider,
  CategoryAllProducts,
  HomeCategorySection,
} from "../components";
import { useDispatch, useSelector } from "react-redux";
import LocationModal from "../components/modals/LocationModal";
import { useMutation } from "@tanstack/react-query";
import apis from "../services";
import { setUser } from "../redux/slice/userSlice";
import toast from "react-hot-toast";
import GooglePlacesAutocomplete, {
  getLatLng,
  geocodeByAddress,
} from "react-google-places-autocomplete";
import axios from "axios";
import { getMessaging, getToken } from "firebase/messaging";
import { firebaseApp, messaging } from ".././firebase";

const HomeScreen = () => {
  const [showLocSuccess, setShowLocSuccess] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState(null);

  const dispatch = useDispatch();
  let { product } = useSelector((product) => product.product);
  let { user } = useSelector((store) => store.user);
  console.log("user", user);
  useEffect(() => {
   if(user)
   {
    if (!user?.lat && !user?.long) {
      setShowLocSuccess(true);
    }
   }
    
  }, [user]);
  console.log("ALL CATEGORY PRODUCT:", product);

  const { mutate, isPending } = useMutation({
    mutationFn: apis.updateProfile,
    onError: function ({ message }) {
      console.log("MESSAGE ERROR:", message);
      toast.error(message, { id: 1 });
    },
    onSuccess: ({ data, status }) => {
      setShowLocSuccess(false);
      console.log("data:", data);
      dispatch(setUser(data?.data));
      // toast.success("Profile updated successfully");
    },
  });

  const handleClick = () => {
    console.log("CLICK");

    if (user) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          console.log("latitude", latitude);
          axios
            .get(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyBmaS0B0qwokES4a_CiFNVkVJGkimXkNsk`
            )
            .then((response) => {
              console.log("RESPONSE", response);
              const formattedAddress =
                response.data.results[0].formatted_address;
              // console.log("formattedAddress", formattedAddress);

              mutate({
                lat: latitude,
                long: longitude,
                address: formattedAddress,
              });
            })
            .catch((error) => {
              console.error("Error fetching address:", error);
            });
        },
        (error) => {
          console.error("Error getting user location:", error);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
        }
      );
    }
  };

  useEffect(() => {
    if (selectedPlace) {
      handleAddress(selectedPlace);
    }
  }, [selectedPlace]);
  console.log("selectedPlace", selectedPlace);
  
  const handlePlaceSelect = async (place) => {
    console.log("FUNCTION RUN,", place);
    setSelectedPlace(place);

    try {
      const results = await getLatLng(place);
      console.log("Latitude:", results.lat);
      console.log("Longitude:", results.lng);
    } catch (error) {
      console.error("Error fetching LatLng:", error);
    }
  };
  const handleAddress = ({ label }) => {
    geocodeByAddress(label)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        mutate({
          lat: lat,
          long: lng,
          address: selectedPlace.label,
        })
        // setDataProceed((prevState) => ({
        //   ...prevState,
        //   latitude: lat.toString(),
        //   longitude: lng.toString(),
        //   address: user.address,
        // }));
      })
      .catch((error) => console.error(error));
  };

  const { mutate:mutateUpdateProfile } = useMutation({
    mutationFn: apis.updateProfile,
    onError: function ({ message }) {
    },
    onSuccess: ({ data, status }) => {
      console.log("data:", data);
      dispatch(setUser(data?.data));
    },
  });

  const fetchToken = async () => {
    return getToken(messaging, {
      vapidKey:
        "BEi5L-bkWNbgKBKL3KBiymjzMl1q_rIzhKpl2IhRI8nwE5SMFH2kmx1fXgPSbopXuEZi-7Z804u3s33FVO9EyTY",
    })
      .then(async (currentToken) => {
        if (currentToken) {
          if (user) {
            mutateUpdateProfile({firebase_web_token: currentToken})     
          } 
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
      });
  };

  useEffect(()=>{
    (async () => {
      if (user) {
        await fetchToken();
      }
    })();
  },[])


  return (
    <>
      {/* <BannerSlider /> */}
      <BannerSlider />
      <HomeCategorySection />
      {product?.map((data, index) => {
        return (
          <CategoryAllProducts
            key={index}
            whereIsUsed={"home"}
            index={index}
            data={data}
            className={"col-lg-4 col-md-6 col-12"}
            limit={6}
          />
        );
      })}

      {/* <AddToCartModal show={show} setShow={setShow} />
      <LoginModal showLogin={showLogin} setShowLogin={setShowLogin} />
      <VerifyOTPModal
        showVerifyOTP={showVerifyOTP}
        setShowVerifyOTP={setShowVerifyOTP}
      />
      <OtpSuccessModal
        showOTPSuccess={showOTPSuccess}
        setShowOTPSuccess={setShowOTPSuccess}
      />
      <ThankyouModal
        showTYSuccess={showTYSuccess}
        setShowTYSuccess={setShowTYSuccess}
      /> */}

      {/* <LocationModal
        showLocSuccess={showLocSuccess}
        setShowLocSuccess={setShowLocSuccess}
        handleClick={handleClick}
        isPending={isPending}
        handlePlaceSelect={handlePlaceSelect}
        handleAddress={handleAddress}
        selectedPlace={selectedPlace}
        setSelectedPlace={setSelectedPlace}
      /> */}
    </>
  );
};

export default HomeScreen;
