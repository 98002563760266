import React from 'react'
import { CategoryAllProducts, ScreenTopBanner } from '../components';

const Promo = () => {
  return (
    <section className="container-fluid" id="jtc-token">
    {/* <div className="heading-bg">
      <h2>Favorite</h2>
    </div> */}
    <ScreenTopBanner title="PROMO"/>
    <section className="product-details-page-three">
      <div className="container-fluid">
        {Array(6)
          .fill()
          .map((data, index) => {
            return (
              <CategoryAllProducts
                whereIsUsed={"false"}
                index={index}
                data={data}
                className={"col-lg-4 col-md-6 col-12"}
              />
            );
          })}
      </div>
    </section>
  </section>
  )
}

export default Promo
