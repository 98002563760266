import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import { useQuery } from "@tanstack/react-query";
import apis from "../services";
import SliderCard from "./shared/SliderCard";
import { Navigation } from "swiper";
import SwiperCore from "swiper";
import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";

// SwiperCore.use([Navigation]);

const BannerSlider = () => {
  const sliderRef = useRef(null);
  const { t } = useTranslation();


  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  const {
    isLoading: loading,
    error: isError,
    data: { data: getStoreDetails } = {},
  } = useQuery({
    queryKey: ["getStoreDiscount"],
    queryFn: () => apis.getStoreDiscountBanner(),
    onError: (error) => {
      console.error("Error fetching store discount:", error);
      // toast.error("Failed to fetch products. Please try again later.");
    },
  });

    console.log("getStoreDetails:", getStoreDetails);
  return (
    <section className="home-sec-1">
      <div className="container-fluid p-0">
        <div className="home-main-slider">
          <Swiper
            ref={sliderRef}
            //   spaceBetween={50}
            slidesPerView={1}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
            // navigation={{
            //   prevEl: "home-slider-prev",
            //   nextEl: "home-slider-next",
            // }}
          >
            {getStoreDetails?.results?.map((data, index) => {
              return (
                <>
                  <SwiperSlide style={{}} key={index}>
                    <SliderCard data={data} index={index} />
                  </SwiperSlide>
                </>
              );
            })}
          </Swiper>
          <div className="home-slider-button-parent">
            <button className="home-slider-prev" onClick={handlePrev}>
            {t("buttons.prev")}
            </button>
            <button className="home-slider-next" onClick={handleNext}>
            {t("buttons.next")}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerSlider;
