import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyCPGrBB7zXW14ompkFhDs0zyN8u3RN53vE",
    authDomain: "jtc-pos.firebaseapp.com",
    projectId: "jtc-pos",
    storageBucket: "jtc-pos.appspot.com",
    messagingSenderId: "837126375756",
    appId: "1:837126375756:web:18170cc39d6366798327e9",
    measurementId: "G-NK222DXE4Y"
  };

export const firebaseApp = initializeApp(firebaseConfig);
export const messaging = getMessaging(firebaseApp);
