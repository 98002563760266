import React from "react";
import { ScreenTopBanner } from "../components";
import { useTranslation } from "react-i18next";

const TermsOfUsePolicy = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className="container-fluid" id="jtc-token">
        <ScreenTopBanner title={t("titles.terms-use")} />
        <div id="coupon-parent" className="coupon-page">
          <div>
            <p>{t("terms-use.welcome")}</p>
            <h4>{t("terms-use.license")}</h4>
            <p>{t("terms-use.license-description")}</p>
            <h4>{t("terms-use.restrictions")}</h4>
            <ul>
              {t("terms-use.restrictions-list", { returnObjects: true }).map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
            <h4>{t("terms-use.limitation-liability")}</h4>
            <p>{t("terms-use.limitation-liability-description")}</p>
            <h4>{t("terms-use.governing-law")}</h4>
            <p>{t("terms-use.governing-law-description")}</p>
            <h4>{t("terms-use.contact-us")}</h4>
            <p>{t("terms-use.contact-us-description")}</p>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsOfUsePolicy;
