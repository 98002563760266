import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import i18next from "i18next";

const ProtectedRoute = () => {
  const { pathname } = useLocation();
  const { user } = useSelector((store) => store.user);
  function redirectToPath(path) {
    window.location.replace(path)
}
        return user ? <Outlet /> : redirectToPath(`/${i18next.language}/login`);
};
export default ProtectedRoute;
