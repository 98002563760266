import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaFacebook } from "react-icons/fa";
import apis from "../services";

import { toast } from "react-hot-toast";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const location = useLocation();
  const routesWithoutFooter = ["/login", "/signup"];
  const { t } = useTranslation();

  let [email, setEmail] = useState("");
  const [shouldRenderFooter, setShouldRenderFooter] = useState(
    !routesWithoutFooter.includes(location.pathname)
  );

  // useEffect(() => {
  //   setShouldRenderFooter(!routesWithoutFooter.includes(location.pathname));
  // }, [location.pathname]);

  const { mutate, isPending, data } = useMutation({
    mutationFn: (data) => apis.newsFeed(data),
    onError: function ({ message }) {
      toast.error(message);
    },
    onSuccess: ({ data, status }) => {
      toast.success(t("responses.toast.emailSubscribe"));

      setEmail("");
    },
  });

  const submitHandler = async (e) => {
    e.preventDefault();
    mutate({ email: email });
  };

  return (
    <React.Fragment>
      {shouldRenderFooter && (
        <footer className="">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12 align-self-center increase">
                <div className="left">
                  <Link to="/">
                    <img
                      src="/assets/images/infyom.png"
                      alt=""
                      style={{ width: 150 }}
                    />
                  </Link>
                  {/* <p
                    className="card-title pt-2 pb-1"
                    style={{ color: "#fff", textAlign: "left" }}
                  >
                    OUR MISSION
                  </p> */}
                  <p className="para muted">{t("descriptions.footerDesc1")}</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 increase">
                <div className="center">
                  <p
                    className="card-title pt-2 pb-1"
                    style={{ color: "#fff", textAlign: "left" }}
                  >
                    {t("titles.contactUs")}
                  </p>
                  <p className="para muted">{t("descriptions.footerDesc2")}</p>
                  {/* <p
                    className="card-title pt-2 pb-1"
                    style={{ color: "#fff", textAlign: "left" }}
                  >
                    OUR MISSION
                  </p>
                  <p className="para muted">
                    Our mission is to be the leading Blockchain Technology
                    provider for the specialty coffee industry. Committed to
                    top-quality products and exceptional service, we aim to
                    create opportunities for our employees and showcase our
                    expertise. Our goal is to make a positive impact on the
                    industry by demonstrating the value of Blockchain
                    Technology, one customer at a time.
                  </p> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-12 increase">
                <div className="right">
                  <p
                    className="card-title pt-2 pb-1"
                    style={{ color: "#fff", textAlign: "left" }}
                  >
                    {t("titles.newsLetter")}
                  </p>
                  <p className="para muted">
                    {" "}
                    {t("descriptions.footerDesc3")}{" "}
                  </p>
                  <form onSubmit={submitHandler}>
                    <div className="joint-input my-3">
                      <input
                        type="email"
                        placeholder={t("placeholders.yourEmail")}
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        required
                      />
                      <button
                        disabled={isPending}
                        className="button primary-button"
                        type="submit"
                      >
                        {isPending
                          ? t("buttons.submitting")
                          : t("buttons.submit")}
                      </button>
                    </div>
                  </form>
                  <div className="wrapp">
                    <p
                      className="card-title pt-2 pb-1"
                      style={{
                        color: "#fff",
                        textAlign: "left",
                        maxWidth: "max-content",
                      }}
                    >
                      {t("titles.followUs")}
                    </p>
                    <p
                      className="card-title pt-2 pb-1"
                      style={{
                        color: "#fff",
                        textAlign: "left",
                        cursor: "pointer",
                      }}
                    >
                      <Link to={"/privacy-policy"} style={{ color: "#fff" }}>
                        {t("titles.privacy_Policy")}
                      </Link>
                    </p>
                    <p
                      className="card-title pt-2 pb-1"
                      style={{
                        color: "#fff",
                        textAlign: "left",
                        cursor: "pointer",
                      }}
                    >
                      <Link to={"/term-of-use"} style={{ color: "#fff" }}>
                        {t("titles.terms_condition")}
                      </Link>
                    </p>
                  </div>
                  <div className="flex-list my-2">
                    <Link
                      to="https://twitter.com/javatimescaffe?t=Q2myIHKv4waNHL3Wev-Q-g&s=09"
                      target="_blank"
                    >
                      <img
                        src="/assets/images/icons/twitter.png"
                        className="md-icon"
                        alt="social icon"
                      />
                    </Link>
                    <Link
                      to="https://www.youtube.com/watch?v=13bmTsSj54I&t=4s"
                      target="_blank"
                    >
                      <img
                        src="/assets/images/icons/youtube.png"
                        className="md-icon"
                        alt="social icon"
                      />
                    </Link>
                    <Link
                      to="https://www.tiktok.com/@antonioleite9"
                      target="_blank"
                    >
                      <img
                        src="/assets/images/icons/tiktok.png"
                        className="md-icon"
                        alt="social icon"
                      />
                    </Link>
                    <Link
                      to="https://www.facebook.com/JavaTimesCaffe?mibextid=ZbWKwL"
                      target="_blank"
                    >
                      <img
                        src="/assets/images/facebook.png"
                        className="md-icon"
                        alt="social icon"
                      />
                    </Link>
                    <Link
                      to="https://www.instagram.com/javatimescaffe/?igshid=NmE0MzVhZDY%3D"
                      target="_blank"
                    >
                      <img
                        src="/assets/images/instagram.png"
                        className="md-icon"
                        alt="social icon"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      )}
    </React.Fragment>
  );
};

export default Footer;
