import React from 'react'
const PageLoader = () => {
    return (
        <div className='pageloader'>
            <div>
                <img src="logo.webp" alt="" />
            </div>
        </div>
    )
}

export default PageLoader;