import React, { useEffect, useState } from "react";
import {
  CategoryAllProducts,
  ProductCard,
  ScreenTopBanner,
} from "../components";
import { useQuery } from "@tanstack/react-query";
import apis from "../services";
import { useTranslation } from "react-i18next";

const Favorite = ({ className = "col-lg-4 col-md-6 col-12" }) => {
  const { t } = useTranslation();

  const {
    isLoading,
    error,
    refetch,
    data: { data: getProductFavourite } = {},
  } = useQuery({
    queryKey: ["getProductFavourite"],
    queryFn: () => apis.getFavouriteProduct(),
    onError: (error) => {
      console.error("Error geting Favourite:", error);
      // toast.error("Failed to fetch products. Please try again later.");
    },
  });
  console.log("getProductFavourite", getProductFavourite);


  return (
    <>
      {isLoading ? (
        <div className="loaderWrapper-cart">
          <div className="loader"></div>
        </div>
      ) : (
        <section className="container-fluid" id="jtc-token">
          {/* <div className="heading-bg">
          <h2>Favorite</h2>
        </div> */}
          <ScreenTopBanner title={t('titles.favourite')}/>
          {/* <section className="product-details-page-three">
          <div className="container-fluid"> */}

          <section className="home-sec-3" id="sec3">
            <div className="wrapper container">
              <div className="container">
                <div className="main-heading">
                  {/* <h2>{data?.product?.name}</h2> */}
                </div>

                <div className="row">
                  {getProductFavourite?.data?.favourites.length > 0 ? (
                    getProductFavourite?.data?.favourites?.map(
                      (data, index) => {
                        // console.log("DATA:", data);
                        return (
                          <ProductCard
                            key={index}
                            className={className}
                            data={data}
                            refetch={refetch}
                          />
                        );
                      }
                    )
                  ) : (
                    <p className="no-order">No favorites found</p>
                  )}
                </div>
              </div>
            </div>
          </section>
        </section>
      )}
    </>
  );
};

export default Favorite;
