import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import GooglePlacesAutocomplete, {
  getLatLng,
  geocodeByAddress,
} from "react-google-places-autocomplete";
import { Link } from "react-router-dom";
import { ImCross } from "react-icons/im";
import { useTranslation } from "react-i18next";

const LocationModal = ({
  showLocSuccess,
  setShowLocSuccess,
  handleClick,
  isPending,
  handlePlaceSelect,
  handleAddress,
  selectedPlace,
  setSelectedPlace,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={showLocSuccess}
      onHide={() => setShowLocSuccess(false)}
      centered
      size="lg"
      className="location-modal"
      backdrop="static"
      keyboard={false}
    >
      <div className="modal-content">
        <div
          className="cross-sign-container"
          onClick={() => setShowLocSuccess(false)}
        >
          <ImCross />
        </div>
        <div className="modal-body" bis_skin_checked="1">
          <img src="/assets/images/thankyou.png" alt="thankyou" />
          {/* <h3>Thankyou!</h3>
          <Link to="/">GO HOME</Link> */}
          <p>{t('descriptions.locDesc')}</p>
        </div>
        <div className="down">
          <div className="btn-wrapper-loc">
            <button onClick={handleClick}>
              {isPending ? t('buttons.loading') : t('buttons.useCurrentLoc')}
            </button>
          </div>
          <div className="input-wrapper">
            {/* <input
              type="text"
              name="location"
              id=""
              placeholder="Enter Manually"
            /> */}
            <GooglePlacesAutocomplete
              apiKey="AIzaSyBmaS0B0qwokES4a_CiFNVkVJGkimXkNsk"
              
              selectProps={{
                selectedPlace,
                onChange: setSelectedPlace,
                onSelect: handlePlaceSelect,
                placeholder: t('placeholders.address'),
              }}
            />
          </div>
          {/* <div className="location-submit-wrapper">
            <button >Submit</button>
          </div> */}
        </div>
      </div>
    </Modal>
  );
};

export default LocationModal;
