import React from "react";
import { useTranslation } from "react-i18next";
import detectLang from "../helper/detectLanguage";

const RewardCard = ({ data, isLoading }) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  console.log("data wallet", data);

  // Sort wallet_transactions by date in descending order
  // const reversedTransactions = data?.wallet_transactions?.slice().reverse();
  // console.log("reversedTransactions",reversedTransactions)

  if (isLoading) {
    return (
      <div className="loaderWrapper-cart">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <>
      {data && data?.wallet_transactions?.length > 0 ? (
        data?.wallet_transactions
          .map((data, index) => {
            // console.log("datadata",data)
            return (
              <div className="main-coupon" key={index}>
                {/* <p className="time">Today 02:50 PM </p> */}
                <div className="coupon ">
                  <div className="left">
                    <img src="/assets/images/Frame.png" alt="product" />
                  </div>
                  <div className="right">
                    <div className="one">
                      <h2>
                        {detectLang(data, "message", lang)}
                        {/* {data?.message} */}
                      </h2>
                      {/* <div className="two">
                    <p>QTY: 02</p>
                    <p>$3000.00</p>
                  </div> */}
                      {/* <p className="earn-reward">
                        <span> {data?.points} JTC Points</span>
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
            );
          })
      ) : (
        <p className="no-order#coupon-parent .coupon-wrapper d-flex justify-content-center">
          {t("messages.noTransaction")}
        </p>
      )}
    </>
  );
};

export default RewardCard;
