import React, { useEffect, useState } from "react";
import { ScreenTopBanner } from "../components";
import { useTranslation } from "react-i18next";

import { Container, Row, Col } from "react-bootstrap";

const TermsPolicy = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      
        <section className="container-fluid" id="jtc-token">
          <ScreenTopBanner title={t("titles.terms-conditions")} />
          <div id="coupon-parent" className="coupon-page">
            
          <Container>
      <Row>
        <Col>
          <h1>{t("terms-policy.effective-date")}</h1>
          <p>{t("terms-policy.intro")}</p>

          <h2>{t("terms-policy.personal-information.title")}</h2>
          <p>{t("terms-policy.personal-information.content")}</p>

          <h2>{t("terms-policy.non-personal-information.title")}</h2>
          <p>{t("terms-policy.non-personal-information.content")}</p>

          <h2>{t("terms-policy.usage.title")}</h2>
          <p>{t("terms-policy.usage.content")}</p>
          <ul>
            {t("terms-policy.usage.purposes", { returnObjects: true }).map((purpose, index) => (
              <li key={index}>{purpose}</li>
            ))}
          </ul>

          <h2>{t("terms-policy.protection.title")}</h2>
          <p>{t("terms-policy.protection.content")}</p>

          <h2>{t("terms-policy.sharing.title")}</h2>
          <p>{t("terms-policy.sharing.content")}</p>

          <h2>{t("terms-policy.changes.title")}</h2>
          <p>{t("terms-policy.changes.content")}</p>

          <h2>{t("terms-policy.acceptance.title")}</h2>
          <p>{t("terms-policy.acceptance.content")}</p>
        </Col>
      </Row>
    </Container>
          </div>
        </section>
     </>
  );
};

export default TermsPolicy;
