import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import apis from "../services";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { setCart } from "../redux/slice/productSlice";
import { useTranslation } from "react-i18next";
import detectLang from "../helper/detectLanguage";

const ProductDetail = () => {
  const { productdetailId } = useParams();

  let { user } = useSelector((store) => store.user);
  // console.log("user",user.id)

  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const [activeVariation, setActiveVariation] = useState("small");

  // console.log("productdetailId:", productdetailId);
  const [activeItem, setActiveItem] = useState(null);
  const [count, setCount] = useState(1);
  const dispatch = useDispatch();
  const [dataCart, setDataCart] = useState({
    product_id: null,
    addons: [],
    quantity: 1,
    message: "",
  });
  useEffect(() => {
    setDataCart((previousData) => ({
      ...previousData,
      product_id: Number(productdetailId),
    }));
  }, [productdetailId]);
  //GET
  const {
    isLoading,
    error,
    refetch: refetchData,
    data: { data: getProductDetail } = {},
  } = useQuery({
    queryKey: ["productDetail"],
    queryFn: () => apis.getProductDetails(productdetailId, user?.id),
    onError: (error) => {
      console.error("Error fetching products:", error);
      toast.error("Failed to fetch products. Please try again later.");
    },
  });
  console.log("PRODUCT DETAIL DATA", getProductDetail);

  //POST
  const { mutate, isPending } = useMutation({
    mutationFn: apis.addToCart,
    onError: function ({ message }) {
      toast.error(message);
    },
    onSuccess: ({ data: addToCart, status }) => {
      console.log("DATA-USER-API-addToCart:", addToCart);
      if (status === 200) {
        // setCount(1);
        // setDataCart({
        //   product_id: null,
        //   addons: [],
        //   quantity: 1,
        //   message: "",
        // });
        dispatch(setCart(addToCart?.data));
        toast.success(t("responses.toast.addedToCart"));
      }
    },
  });

  const getAddVariation = (variation) => {
    setActiveVariation(variation);
    setDataCart((previousData) => ({
      ...previousData,
      product_variation: variation,
    }));
  };

  const getAddOnsID = (addOnID, itemID) => {
    setActiveItem(itemID);
    console.log("addOnID:", addOnID);
    console.log("itemID:", itemID);

    setDataCart((previousData) => {
      console.log(previousData, "previousData");

      // Check if the addon already exists in the cart
      const existingAddOnIndex = previousData.addons.findIndex(
        (addon) => addon.addon_id === addOnID
      );

      if (existingAddOnIndex !== -1) {
        // Toggle the selection of the item within the addon
        const updatedAddons = previousData.addons.map((addon) => {
          if (addon.addon_id === addOnID) {
            // Toggle the selection of the itemID
            const itemIndex = addon.addon_items_id.indexOf(itemID);
            if (itemIndex !== -1) {
              // Item exists, remove it
              addon.addon_items_id.splice(itemIndex, 1);
            } else {
              // Item doesn't exist, add it
              addon.addon_items_id.push(itemID);
            }
          }
          return addon;
        });

        return {
          ...previousData,
          addons: updatedAddons,
        };
      } else {
        // If the addon doesn't exist, add it along with the new itemID
        return {
          ...previousData,
          addons: [
            ...previousData.addons,
            { addon_id: addOnID, addon_items_id: [itemID] },
          ],
        };
      }
    });
  };
  const decrement = () => {
    if (count > 1) {
      setCount(count - 1);
      setDataCart((previousData) => ({
        ...previousData,
        quantity: count - 1,
      }));
    }
  };
console.log(getProductDetail?.data?.attributes?.quantity_limit,"getProductDetail?.data?.attributes?.quantity_limit")
  const increment = () => {
    if(getProductDetail?.data?.attributes?.quantity_limit)
      {
    if(dataCart?.quantity >= getProductDetail?.data?.attributes?.quantity_limit){
      return toast.error(t("messages.limitOrder"),{id:1})
    }
  }
    setCount(count + 1);
    setDataCart((previousData) => ({
      ...previousData,
      quantity: count + 1,
    }));
  };
  const transformAddonData = (addons) => {
    console.log(addons, "addonsaddons");
    let transformedData = [];

    addons.forEach((addon) => {
      addon.addon_items_id.forEach((item) => {
        transformedData.push({
          addon_id: addon.addon_id,
          addon_items_id: item,
        });
      });
    });

    return transformedData;
  };
  // console.log("dataCartFINAL", dataCart);
  const handleCartSubmit = () => {
    if (!user) {
      return toast.error(t("responses.toast.errorLogin"), { id: 1 });
    }

    const transformedAddons = transformAddonData(dataCart.addons);

    const transformedDataCart = {
      ...dataCart,
      addons: transformedAddons,
    };

    mutate(transformedDataCart);
  };

  const { mutate: mutateFavouite, data: { data: favouriteProduct } = {} } =
    useMutation({
      mutationFn: apis.favouriteProduct,
      onError: function ({ message }) {
        toast.error(message);
      },
      onSuccess: ({ status, data }) => {
        refetchData();
        toast.success(data?.message);
        // setIsFavorite(favourite?.data?.product?.is_favourite);
      },
    });

  const handleFavourite = (event) => {
    event.preventDefault();
    event.stopPropagation();
    console.log("CLICK");
    // console.log("DATA ID", data.id);
    if (user) {
      mutateFavouite({
        product_id: Number(getProductDetail?.data?.attributes?.id),
      });
    } else {
      return toast.error(t("responses.toast.errorLogin"), { id: 1 });
    }
  };

  const calculateTotalBill = () => {
    let total = 0;

    // Add variant price

  if (activeVariation === "small") {
    total += parseFloat(getProductDetail?.data?.attributes?.small);
  } else if (activeVariation === "medium") {
    total += parseFloat(getProductDetail?.data?.attributes?.medium);
  } else if (activeVariation === "large") {
    total += parseFloat(getProductDetail?.data?.attributes?.large);
  }



    // Add addon prices
    dataCart.addons.forEach((addon) => {
      addon.addon_items_id.forEach((itemId) => {
        const addonItem = getProductDetail?.data?.attributes?.product_addons
          ?.flatMap((addon) => addon.addon_items)
          .find((item) => item.item_id === itemId);
        if (addonItem) {
          total += parseFloat(addonItem.item_price);
        }
      });
    });

    // Multiply by quantity
    total *= count;

    return total.toFixed(2); // Return total bill with two decimal places
  };

  const calculateTotalBillToken = () => {
    let total = 0;

    // Add variant price
    if (activeVariation === "small") {
      total += parseFloat(
        getProductDetail?.data?.attributes?.small_token_price
      );
    } else if (activeVariation === "medium") {
      total += parseFloat(
        getProductDetail?.data?.attributes?.medium_token_price
      );
    } else if (activeVariation === "large") {
      total += parseFloat(
        getProductDetail?.data?.attributes?.large_token_price
      );
    }

    // Add addon prices
    dataCart.addons.forEach((addon) => {
      addon.addon_items_id.forEach((itemId) => {
        const addonItem = getProductDetail?.data?.attributes?.product_addons
          ?.flatMap((addon) => addon.addon_items)
          .find((item) => item.item_id === itemId);
        if (addonItem) {
          total += parseFloat(addonItem.item_price);
        }
      });
    });

    // Multiply by quantity
    total *= count;

    return total.toFixed(2); // Return total bill with two decimal places
  };

  return (
    <>
      {isLoading ? (
        <div className="loaderWrapper-cart">
          <div className="loader"></div>
        </div>
      ) : (
        <section className="product-details-page">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <ul className="breadcrums">
                  <Link to="/">
                    {" "}
                    <li>{t("titles.home")} -</li>
                  </Link>
                  <li style={{ color: "#000000" }}>
                    {detectLang(
                      getProductDetail?.data?.attributes,
                      "name",
                      lang
                    )}
                    {/* {getProductDetail?.data?.attributes?.name}{" "} */}
                  </li>
                  {/* <li>
                  <span>Ferrero Rocher</span>
                </li> */}
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="img-product-details">
                  <img
                    src={getProductDetail?.data?.attributes?.images[0]}
                    alt="detail-img"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="product-details-d">
                  <div className="product-name-con">
                    <h2>
                      {detectLang(
                        getProductDetail?.data?.attributes,
                        "name",
                        lang
                      )}
                      {/* {getProductDetail?.data?.attributes?.name} */}
                    </h2>
                    <div className="right" id="favoriteIconContainer">
                      <div className="right" onClick={handleFavourite}>
                        {console.log(
                          "getProductDetail?.data?.attributes?.is_favourite",
                          getProductDetail?.is_favourite
                        )}
                        <svg
                          width="19"
                          height="18"
                          viewBox="0 0 19 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18.5442 5.56959V6.36113C18.5323 6.4063 18.5156 6.45146 18.5133 6.499C18.4419 7.33094 18.1995 8.11773 17.8548 8.87361C17.2083 10.2927 16.2575 11.5002 15.1855 12.615C13.4764 14.393 11.5154 15.8548 9.45455 17.1931C9.36185 17.2525 9.30005 17.2406 9.21685 17.1859C7.4555 16.0402 5.76308 14.8066 4.23943 13.3542C3.19831 12.363 2.23801 11.3053 1.47737 10.0787C0.776157 8.95918 0.267481 7.77069 0.158139 6.43482C0.0369127 4.96822 0.429116 3.65374 1.36327 2.52229C3.24823 0.240382 6.54987 -0.087643 8.86743 1.76166C9.02907 1.89001 9.1812 2.03263 9.34521 2.17525C9.42127 2.10632 9.48545 2.04452 9.55201 1.98509C9.93232 1.63092 10.3531 1.33617 10.8237 1.11511C12.4614 0.344969 14.1016 0.366362 15.6965 1.20544C17.0419 1.91378 17.9285 3.02859 18.3397 4.49995C18.4372 4.84937 18.4776 5.21305 18.5465 5.57197L18.5442 5.56959Z"
                            fill={
                              getProductDetail?.is_favourite
                                ? "#E0201C"
                                : "#3E3E3E"
                            }
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <p className="desc">
                    {detectLang(
                      getProductDetail?.data?.attributes,
                      "description",
                      lang
                    )}

                    {/* {getProductDetail?.data?.attributes?.description} */}
                  </p>

                  {getProductDetail?.data?.attributes?.is_variant_check ===
                    1 && (
                    <div className="variation-cart">
                      <p className="main-head"> {t("titles.variation")}</p>
                      <div className="variation-item-con">
                        <div
                          className={`one ${
                            activeVariation === "small" ? "active" : ""
                          }`}
                          onClick={() => getAddVariation("small")}
                        >
                          <p>{t("titles.small")}</p>
                          <p className="text-center">
                            $
                            {(+getProductDetail?.data?.attributes
                              ?.small).toFixed(2)}
                          </p>
                        </div>
                        <div
                          className={`one ${
                            activeVariation === "medium" ? "active" : ""
                          }`}
                          onClick={() => getAddVariation("medium")}
                        >
                          <p>{t("titles.medium")}</p>
                          <p className="text-center">
                            $
                            {(+getProductDetail?.data?.attributes
                              ?.medium).toFixed(2)}
                          </p>
                        </div>
                        <div
                          className={`one ${
                            activeVariation === "large" ? "active" : ""
                          }`}
                          onClick={() => getAddVariation("large")}
                        >
                          <p>{t("titles.large")}</p>
                          <p className="text-center">
                            $
                            {(+getProductDetail?.data?.attributes
                              ?.large).toFixed(2)}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {getProductDetail?.data?.attributes?.product_addons.length >
                    0 && (
                    <>
                      <p className="addons">{t("titles.addOns")}</p>
                      <div className="size">
                        {getProductDetail?.data?.attributes?.product_addons?.map(
                          (dataItem, index) => {
                            return (
                              <>
                              {console.log("dataItemdataItem",dataItem)}
                                {dataItem?.addon_items.length > 0 && (
                                  <h3>{dataItem?.addon_name}</h3>
                                )}
                                <div className="down">
                                  {dataItem?.addon_items.map(
                                    (dataOption, index) => {
                                      return (
                                        <div
                                          // className="one"
                                          onClick={() =>
                                            getAddOnsID(
                                              dataItem?.addon_id,
                                              dataOption?.item_id
                                            )
                                          }
                                          className={`one size ${
                                            dataCart?.addons.some((addon) =>
                                              addon.addon_items_id.includes(
                                                dataOption?.item_id
                                              )
                                            )
                                              ? "active"
                                              : ""
                                          }`}
                                        >
                                          <h3>{dataOption?.item_name}</h3>
                                          <p>MX ${dataOption?.item_price}</p>
                                        </div>

                                        // <h1>{data?.item_name}</h1>
                                      );
                                    }
                                  )}
                                </div>
                              </>
                            );
                          }
                        )}
                      </div>
                    </>
                  )}

                  {/* <div className="size">
                  <h3>SIZE</h3>
                  <div className="down">
                    <div className="one" onClick="changeSizeBG(this)">
                      <h3>Small</h3>
                      <p>MX $2.99</p>
                    </div>
                    <div className="one" onClick="changeSizeBG(this)">
                      <h3>Medium</h3>
                      <p>MX $2.99</p>
                    </div>
                    <div className="one" onClick="changeSizeBG(this)">
                      <h3>Large</h3>
                      <p>MX $2.99</p>
                    </div>
                    <div className="one" onClick="changeSizeBG(this)">
                      <h3>Extra Large</h3>
                      <p>MX $2.99</p>
                    </div>
                  </div>
                </div>
                <div className="size bread">
                  <h3>Bread</h3>
                  <div className="down">
                    <div className="one" onClick="changeSizeBG(this)">
                      <h3>Brown</h3>
                      <p>MX $2.99</p>
                    </div>
                    <div className="one" onClick="changeSizeBG(this)">
                      <h3>White</h3>
                      <p>MX $2.99</p>
                    </div>
                  </div>
                </div> */}
                  <div className="price">
                    <h2>
                      MX <span>${calculateTotalBill()}</span>
                    </h2>
                    <div className="down-price">
                      <img
                        src="/assets/images/product-detail-logo.png"
                        alt="product"
                      />
                      <p>{calculateTotalBillToken()}</p>
                    </div>
                  </div>
                  <div className="counter">
                    <button className="btn-1" onClick={decrement}>
                      <svg
                        width="12"
                        height="3"
                        viewBox="0 0 12 3"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.16 0.875555L0.16 2.14993C0.16 2.39262 0.356737 2.58936 0.599424 2.58936L11.2406 2.58935C11.4833 2.58935 11.68 2.39262 11.68 2.14993L11.68 0.875555C11.68 0.632867 11.4833 0.43613 11.2406 0.43613L0.599424 0.436131C0.356737 0.436131 0.16 0.632868 0.16 0.875555Z"
                          fill="#3E3E3E"
                        />
                      </svg>
                    </button>
                    <p id="counterValue">{count}</p>
                    <button className="btn-2" onClick={increment}>
                      <svg
                        width="12"
                        height="13"
                        viewBox="0 0 12 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.401 5.48358H7.15693V1.23951C7.15693 0.996708 6.96027 0.800049 6.71747 0.800049H5.44344C5.20064 0.800049 5.00398 0.996708 5.00398 1.23951V5.48358H0.759466C0.516666 5.48358 0.320007 5.68024 0.320007 5.92304V7.19706C0.320007 7.43986 0.516666 7.63652 0.759466 7.63652H5.00354V11.8806C5.00354 12.1234 5.20019 12.32 5.44299 12.32H6.71702C6.95982 12.32 7.15648 12.1234 7.15648 11.8806V7.63652H11.4005C11.6433 7.63652 11.84 7.43986 11.84 7.19706V5.92304C11.84 5.68024 11.6433 5.48358 11.4005 5.48358H11.401Z"
                          fill="#3E3E3E"
                        />
                      </svg>
                    </button>
                  </div>

                  <div className="product-detail-cart">
                    <button
                      className="fancy-button"
                      type="button"
                      onClick={handleCartSubmit}
                      disabled={isLoading || isPending}
                    >
                      {isPending ? t("buttons.adding") : t("buttons.addToCart")}
                    </button>
                  </div>
                  {/* <button type="submit" data-toggle="modal" data-target="#loginModal">login</button> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {/* <section className="product-details-page-three">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="title">MOST RECENT</h2>
            </div>
          </div>
          <section className="home-sec-3 " style={{ marginTop: "0" }}>
            <div className="wrapper">
              <div className="row">
                {Array(4)
                  .fill()
                  .map((key, index) => {
                    return (
                      <>
                        <div className="col-lg-3 col-md-6 col-12 p-0">
                          <div className="coffee-card">
                            <div className="one">
                              <div className="top">
                                <img
                                  src="/assets/images/card-images/coffee-card1.png"
                                  alt="card1"
                                />
                                <div className="discount-bg">
                                  Get <span>10%</span> Cash back
                                </div>
                                <div className="left">
                                  <img
                                    src="/assets/images/card-images/card-logo.png"
                                    alt="card"
                                  />
                                  <p>100</p>
                                </div>
                                <div className="right">
                                  <img
                                    src="/assets/images/card-images/favourite.png"
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="bottom">
                                <h2>CAPPUCCINO</h2>
                                <p>
                                  Flavour so strong, it’ll awaken all five of
                                  your senses!
                                </p>
                                <p className="price">
                                  MX<span> $66.99</span>
                                </p>
                              </div>
                              <div className="btn-wrapper">
                                <button className="btn-coffee">
                                  ADD TO CART
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </section>
        </div>
      </section> */}
    </>
  );
};

export default ProductDetail;
