import React, { useState } from "react";
import JtcTokenCard from "../components/JtcTokenCard";
import { useQuery } from "@tanstack/react-query";
import apis from "../services";
import PaymentModalToken from "../components/modals/paymentModalToken";
import { loadStripe } from "@stripe/stripe-js";
import { useTranslation } from "react-i18next";


const promise = loadStripe(
  "pk_test_51MvYFsHR4p2bAk2CwOzjbovbZZQI05WyT3OlF7Ov1jEB8s3qApeF7VK1DoZJDpXXg24IcfuEMou7gxeKtjLYpqrd00XW9orPni"
);

const JtcToken = () => {
  const [showModal, setShowModal] = useState(false);
  const [tokenData, setTokenData] = useState("");

  const { t } = useTranslation();


  const handleToggleModal = () => {
    setShowModal(!showModal);
  };
  const {
    isLoading,
    error,
    refetch,
    data: { data: getToken } = {},
  } = useQuery({
    queryKey: ["getToken"],
    queryFn: () => apis.getJtcToken(),
    onError: (error) => {
      console.error("Error geting JTC TOKENS:", error);
      // toast.error("Failed to fetch products. Please try again later.");
    },
  });

  const handleModalOpen = () => {
    // console.log("CLICK OPEN MODAL")
    setShowModal(true);
  };
  console.log("getToken", getToken);
  // console.log("tokenData", tokenData);
  return (
    <>
      {isLoading ? (
        <>
          <div className="loaderWrapper-cart">
            <div className="loader"></div>
          </div>
        </>
      ) : (
        <>
          <section className="container-fluid" id="jtc-token">
            <div className="heading-bg">
              <h2>JTC {t("titles.token")}</h2>
            </div>
            {getToken?.data?.tokenPlanList?.length > 0 ? (
              <div className="main-card">
                {getToken?.data?.tokenPlanList?.map((data, index) => {
                  // console.log("DATA", data);
                  return (
                    <JtcTokenCard
                      data={data}
                      key={index}
                      handleModalOpen={handleModalOpen}
                      setTokenData={setTokenData}
                    />
                  );
                })}
              </div>
            ) : (
              <p className="no-order">{t("messages.noToken")}</p>
            )}
          </section>
          <PaymentModalToken
            showModal={showModal}
            handleToggleModal={handleToggleModal}
            promise={promise}
            data={tokenData}
          />
        </>
      )}
    </>
  );
};

export default JtcToken;
