import React, { useEffect, useState } from "react";
import apis from "../services";
import { useQuery } from "@tanstack/react-query";
import { Pagination, ScreenTopBanner } from "../components";
import { useTranslation } from "react-i18next";
import detectLang from "../helper/detectLanguage";

const Notification = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const [pageNumber, setPageNumber] = useState(1);

  let pageSize = 10;
  // let pageNumber = 1;

  const {
    isLoading,
    error,
    refetch,
    data: { data: getNotify } = {},
  } = useQuery({
    queryKey: ["getNotify"],
    queryFn: () => apis.getNotify(pageSize, pageNumber),
    onError: (error) => {
      console.error("Error geting Order History:", error);
      // toast.error("Failed to fetch products. Please try again later.");
    },
  });
  console.log("getNotify", getNotify);
  console.log("totaltotal", getNotify);

  let total = getNotify?.data?.pagination?.total;

  useEffect(() => {
    // const fetchData = async () => {
    //   try {
    //     await refetch();
    //   } catch (error) {
    //     console.error("Error fetching data:", error);
    //   }
    // };
    refetch();
    // fetchData();
  }, [pageNumber]);

  const handlePageChange = async (page) => {
    setPageNumber(page);
  };

  return (
    <>
      {isLoading ? (
        <>
          <div className="loaderWrapper-cart">
            <div className="loader"></div>
          </div>
        </>
      ) : (
        <section className="container-fluid" id="jtc-token">
          <ScreenTopBanner title={t("titles.notification")} />
          <div id="coupon-parent" className="coupon-page">
            {getNotify?.data.notifications.length > 0 ? (
              getNotify?.data.notifications?.map((data, index) => {
                //   console.log("DATA:", data);
                return (
                  <div className={`coupon ${data?.is_expired}`} key={index}>
                    <div className="left">
                      <img src="/assets/images/Frame.png" alt="product" />
                    </div>
                    <div className="right">
                      <div className="one">
                        <h2>
                          {detectLang(data, "message", lang)}
                          {/* {data?.message} */}
                        </h2>
                        <span>
                          {detectLang(data, "title", lang)}
                          {/* {data?.title} */}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p className="no-order">{t("messages.noNotification")}</p>
            )}
          </div>

          {getNotify?.data?.notifications?.length > 0 && (
            <div className="pagination-con">
              <Pagination
                currentPage={pageNumber}
                totalPages={Math.ceil(total / pageSize)}
                onPageChange={handlePageChange}
              />
            </div>
          )}
        </section>
      )}
    </>
  );
};

export default Notification;
