import React from "react";
import { ScreenTopBanner } from "../components";
import { useQuery } from "@tanstack/react-query";
import apis from "../services";
import RewardCard from "../components/RewardCard";
import { useTranslation } from "react-i18next";

const Wallet = () => {
  const {
    isLoading,
    error,
    refetch,
    data: { data: getUserWallet } = {},
  } = useQuery({
    queryKey: ["getUserWallet"],
    queryFn: () => apis.getUserWallet(),
    onError: (error) => {
      console.error("Error geting user wallet :", error);
      // toast.error("Failed to fetch products. Please try again later.");
    },
  });
  console.log("USER WALLET", getUserWallet);

  const { t } = useTranslation();

  const { isLoading: isLoadingWallet, data: { data: getReferral } = {} } =
    useQuery({
      queryKey: ["getReferral"],
      queryFn: () => apis.getReferral(),
      onError: (error) => {
        console.error("Error geting Referral :", error);
        // toast.error("Failed to fetch products. Please try again later.");
      },
    });

  console.log("getReferral", getReferral);

  return (
    <section className="" id="jtc-token">
      <ScreenTopBanner title={t("titles.my_wallet")} />
      <div className="wallet-wrapper">
        <div className="left">
          <div className="heading-wrapper">
            <p className="wallet-head">{t("titles.avilableBalance")}</p>
            <h2 className="price-wallet">
              $
              {getUserWallet?.availableTokens !== null &&
              getUserWallet?.availableTokens !== undefined
                ? getUserWallet?.availableTokens
                : 0}
            </h2>
            <p className="wallet-mexican">{t("titles.mexicanPesos")}</p>
            <div className="wrap">
              <h2>{t('titles.walletID')}:</h2>
              <p>{getUserWallet?.walletId}</p>
            </div>
          </div>
        </div>
        <div className="right">
          <img src="/assets/images/token-coin.png" alt="token" />
        </div>
      </div>
      <div id="coupon-parent">
        <div className="reward-left">
          <div className="wallet-history">
            <h3>{t("titles.TransactionHistory")}</h3>
          </div>

          <div className="coupon-wrapper" style={{ display: "block" }}>
            <RewardCard data={getReferral} isLoading={isLoadingWallet}/>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Wallet;
